import {
  Container,
  ContentLayout,
  Grid,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import PageLayout from '../components/PageLayout';

export default function () {
  // variables

  // hooks

  // functions

  // render
  return (
    <PageLayout
      content={
        <SpaceBetween size='m'>
          <Container
            header={
              <Header variant='h1'>
                About Kero
              </Header>
            }
          >
            <Grid
              gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
            >
              <div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
              <img src='kero-profile.png' width={'100%'}/>
            </Grid>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </Container>
          <Container
            header={
              <Header variant='h1'>
                Awards
              </Header>
            }
          >
            <Grid
              gridDefinition={[]}
            >
              <img src='medalions/1.png'/>
              <img src='medalions/2.png'/>
              <img src='medalions/3.png'/>
              <img src='medalions/4.png'/>
              <img src='medalions/5.png'/>
              <img src='medalions/6.png'/>
              <img src='medalions/7.png'/>
              <img src='medalions/8.png'/>
              <img src='medalions/9.png'/>
              <img src='medalions/10.png'/>
              <img src='medalions/11.png'/>
              <img src='medalions/12.png'/>
              <img src='medalions/13.png'/>
              <img src='medalions/14.png'/>
              <img src='medalions/15.png'/>
              <img src='medalions/16.png'/>
              <img src='medalions/17.png'/>
              <img src='medalions/18.png'/>
              <img src='medalions/19.png'/>
            </Grid>
          </Container>
        </SpaceBetween>
      }
    />
  );
}