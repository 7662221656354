import '@cloudscape-design/global-styles/index.css';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './pages/LandingPage';
import PortfolioHubPage from './pages/portfolio/PortfolioHubPage';
import ContactPage from './pages/contact-me/ContactPage';

// custom theme
import { applyTheme } from '@cloudscape-design/components/theming';

const theme = {
  tokens: {
    colorBackgroundLayoutMain: { light:'#7af3b8' },
    colorTextTopNavigationTitle: { light:'#300457' },
  },
  contexts: {
    'top-navigation': {
        tokens: {
          colorBackgroundLayoutMain: '#300457',
        },
    },
    header: {
      tokens: {
        colorBackgroundLayoutMain: { light:'#7af3b8' },
        colorTextHeadingDefault: { light:'#000000' },
      }, 
    },
    flashbar: {
      tokens: {
        colorBackgroundLayoutMain: { light:'#7af3b8' },
        colorTextHeadingDefault: { light:'#000000' },
      }
    },
  }
};

const { reset } = applyTheme({ theme });

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' Component={LandingPage}/>
        <Route path='/portfolio' Component={PortfolioHubPage}/>
        <Route path='/contact-me' Component={ContactPage}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
