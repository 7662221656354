import { useState } from 'react';
import {
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import PageLayout from '../../components/PageLayout';

// [N]: Should we show the optional fields by adding '(optional)'?
export default function () {
  // variables
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // hooks

  // functions
  // TODO: add API call to submit feedback
  // [ ]: How would Kero like to get that feedback?
  async function submitForm() {
    // pause the form
    setSending(true);
    setNotifications([]);

    // send API call
    var response = await fetch('https://s1y8e4jgpc.execute-api.us-east-1.amazonaws.com/PROD/contact-me', {
      method: 'POST',
      body: JSON.stringify({
        name: name.trim(),
        company: company.trim(),
        email: email.trim(),
        subject: subject.trim(),
        message: message.trim(),
        timeStamp: (new Date().toString()),
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });

    // parse response body
    var responseBody = {};
    try {
      responseBody = await response.json();
    } catch (err) {}

    // process response
    if (response.status === 200) {
      // add success notification
      setNotifications([
        {
          type: 'info',
          content: 'Thank you for reaching out, I will get back to you as soon as possible.',
          id: 'message_1',
        }
      ]);
    } else if (response.status === 403) {
      // add error message
      setNotifications([
        {
          type: 'error',
          header: '(' + response.status + ') Failed to submit form',
          content: responseBody.errorMessage,
          id: 'message_1',
        }
      ]);
    } else {
      // add error message
      setNotifications([
        {
          type: 'error',
          header: '(' + response.status + ') Failed to submit form',
          content: responseBody.errorMessage,
          id: 'message_1',
        }
      ]);
    }

    // unpause form
    setSending(false);
  }

  // render
  return (
    <PageLayout
      content={
        <Container
          header={
            <Header variant='h1'>
              Contact form
            </Header>
          }
        >
          <Form
            actions={
              <Button
                variant='primary'
                disabled={!name.trim() || !email.trim() || !message.trim() || sending}
                onClick={submitForm}
                loading={sending}
              >
                Submit
              </Button>
            }
          >
            <SpaceBetween size='s'>
              <ColumnLayout columns={2}>
                <FormField label='Name'>
                  <Input
                    value={name}
                    onChange={({detail: {value}}) => {setName(value)}}
                    placeholder='Charon'
                    disabled={sending}
                  />
                </FormField>
                <FormField label='Company'>
                  {/* Optional */}
                  <Input
                    value={company}
                    onChange={({detail: {value}}) => {setCompany(value)}}
                    placeholder='Hades Inc.'
                    disabled={sending}
                  />
                </FormField>
              </ColumnLayout>
              <FormField label='Contact email' stretch={true}>
                <Input
                  value={email}
                  onChange={({detail: {value}}) => {setEmail(value)}}
                  placeholder='ferryman@hades.underworld'
                  disabled={sending}
                />
              </FormField>
              <FormField label='Subject' stretch={true}>
                {/* optional */}
                <Input
                  value={subject}
                  onChange={({detail: {value}}) => {setSubject(value)}}
                  placeholder='Job offer'
                  disabled={sending}
                />
              </FormField>
              <FormField label='Message' stretch={true}>
                <Textarea
                  value={message}
                  onChange={({detail: {value}}) => {setMessage(value)}}
                  placeholder='urrrrrnngghhhhh...'
                  disabled={sending}
                />
              </FormField>
              <Flashbar items={notifications} />
            </SpaceBetween>
          </Form>
        </Container>
      }
    />
  );
}