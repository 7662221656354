
import { useState } from 'react';
import {
  AppLayout,
  Container,
  ContentLayout,
  Header,
  Link,
  SideNavigation,
} from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';

const LOCALE = 'en';

export default function ({ content }) {
  // variable
  const [navOpen, setNavOpen] = useState(false);

  // hooks

  // functions

  // render
  return (
    <I18nProvider locale={LOCALE} messages={[messages]}>
      <AppLayout
        headerSelector='.site-header'
        navigationOpen={navOpen}
        onNavigationChange={({detail: {open}}) => {setNavOpen(open);}}
        navigation={
          <SideNavigation
            activeHref={document.location.pathname}
            items={[
              { type: 'link', text: 'Home', href: '/' },
              { type: 'link', text: 'Portfolio', href: '/portfolio' },
              { type: 'link', text: 'Contact', href: '/contact-me' },
            ]}
            onChange={console.log}
          />
        }
        toolsHide={true}
        content={content}
      />
    </I18nProvider>
  );
}