import { useEffect, useState } from 'react';
import {
  ContentLayout,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import PageLayout from '../../components/PageLayout';
import PortfolioListItem from '../../components/PortfolioListItem';

// [ ]: Can Kero do a JSON, or does the config need to be a CSV?
export default function () {
  // variables
  const [featuresItems, setFeaturedItems] = useState([]);
  const [fantasyItems, setFantasyItems] = useState([]);
  const [scifiItems, setScifiItems] = useState([]);

  // hooks
  // get the list of items from teh servers JSON file
  useEffect(() => {
    try {
      // fetch file from server
      fetch('./portfolio.json')
        .then((response) => response.json())
        .then((json) => {
          try {
            // populate fantasy
            const newFantasy = [];
            json.fantasy.forEach((item, index) => newFantasy.push(<PortfolioListItem {...item} key={`fantasy-${index}`} />));
            setFantasyItems(newFantasy);
          } catch (err) {
            // TODO: add an under construction message
          }

          try {
            // populate scifi
            const newScifi = [];
            json.scifi.forEach((item, index) => newScifi.push(<PortfolioListItem {...item} key={`scifi-${index}`} />));
            setScifiItems(newScifi);
          } catch (err) {
            // TODO: add an under construction message
          }

          try {
            // populate featured
            const newFeatured = [];
            json.featured.forEach((item, index) => newFeatured.push(<PortfolioListItem {...item} key={`featured-${index}`} />));
            setFeaturedItems(newFeatured);
          } catch (err) {
            // TODO: add an under construction message
          }
        });
    } catch (err) {
      // log error
    }
  }, []);

  // functions

  // render
  return (
    <PageLayout
      content={
        <SpaceBetween size='xl'>
          {/* TODO: Kero wants no sections, just a list of items */}
          <ContentLayout
            header={<Header>Games</Header>}
          >
            <SpaceBetween size='xl'>
              {fantasyItems}
            </SpaceBetween>
          </ContentLayout>
          <ContentLayout
            header={<Header>Prose</Header>}
          >
            <SpaceBetween size='xl'>
              {scifiItems}
            </SpaceBetween>
          </ContentLayout>
        </SpaceBetween>
      }
    />
  );
}