import {
  Badge,
  Container,
  Grid,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';


export default function ({ title, description, thumbnail, tags }) {
  // variables

  // hooks

  // functions

  // render
  return (
    <Container>
      <Grid  gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
        <div className='portfolio-thumbnail'>
          <img src={thumbnail} alt={title + 'game image'} />
        </div>
        <div>
          <Header>{title}</Header>
          <p>{description}</p>
          <SpaceBetween direction='horizontal' size='s'>{tags.map(text => <Badge>{text}</Badge>)}</SpaceBetween>
        </div>
      </Grid>
    </Container>
  );
}