import {
  Button,
  TopNavigation,
} from "@cloudscape-design/components";

export default function () {
  // variables

  // hooks

  // functions

  // render
  return (
    <TopNavigation
      identity={{
        href: '/',
        title: 'UnfoldingLetters',
        logo: {
          src: '/logo.png',
          alt: 'UnfoldingLetters'
        }
      }}
      utilities={[
        {
          type: 'button',
          text: 'Contact me',
          href: '/contact-me',
        }
      ]}
    >
      <div className='logo'>
        {/* TODO: Add dynamoc name for the website */}
        UnfoldingLetters
      </div>
      <div className='actions'>
        <Button variant='primary' href='/contact-me'>
          Contact me
        </Button>
      </div>
    </TopNavigation>
  );
}